import cn from 'classnames';

import {ColorSchemeEnum} from '@/enums';
import ShopifyLogo from '@/components/base/elements/Logo/ShopifyLogo';
import {
  ShopifyLogoColor,
  ShopifyLogoTheme,
} from '@/components/base/elements/Logo/types';
import Section from '@/components/brochureV2/primitive/Section/Section';
import Anchor from '@/components/shared/Anchor/Anchor';
import type {NavItem} from '@/components/shared/NavList/NavList';
import NavList from '@/components/shared/NavList/NavList';
import SocialIcons from '@/components/shared/SocialIcons/SocialIcons';
import {
  PRIVACY_PREFERENCES_MODAL_URL_COMPONENT_NAME,
  PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY,
} from '@/constants';
import {
  useCookieNoticeBanner,
  useFooterManageConsent,
} from '@/hooks/useCookieNoticeBanner';
import {kebab} from '@/utils/utils';
import {useTranslations} from '@/hooks/useTranslations';
import type {I18NOptions, LinkComponent, NavigationListItem} from '@/types';
import shopify from '@/hooks/navigation/footer/shopify';
import resources from '@/hooks/navigation/footer/resources';
import developers from '@/hooks/navigation/footer/developers';
import products from '@/hooks/navigation/footer/products';
import globalImpact from '@/hooks/navigation/footer/global-impact';
import solutions from '@/hooks/navigation/footer/solutions';
import {legalPrivacyPath, legalTermPath} from '@/hooks/navigation/utils';
import {UrlUtils} from '@/utils/UrlUtils';
import {linkColor} from '@/components/brochureV2/section/Footer/utils';
import type {SocialLink} from '@/components/shared/SocialIcons/SocialIcons';

import {getSocialLinks} from '../utils';

interface FooterProps {
  colorScheme?: ColorSchemeEnum;
  sectionName?: string;
  className?: string;
  navItemClassName?: string;
  navItemsBoxClassName?: string;
  navFn?: (opts: I18NOptions) => NavigationListItem[];
  legalNavFn?: (opts: I18NOptions) => LinkComponent[];
  socialLinksFn?: (opts: I18NOptions) => SocialLink[];
}

const relativeToAbsoluteURLconverter = (
  url: string,
  domain: string = 'https://www.shopify.com/',
) => {
  return url.replace(/^\//, domain);
};

const defaultFooterLegalNav = ({
  t,
  site,
  localizePath,
}: I18NOptions): LinkComponent[] => {
  const urlUtils = new UrlUtils(site);
  let links: LinkComponent[] = [
    {text: t('footer:termsOfService'), url: legalTermPath(site)},
    {text: t('footer:privacyPolicy'), url: legalPrivacyPath(site)},
    // web client will hide if not applicable
    {text: t('footer:managePrivacy'), url: '#privacy'},
    {text: t('footer:sitemap'), url: localizePath('/sitemap')},
    {text: t('footer:privacyChoices'), url: urlUtils.getUrl('/', 'privacy')},
  ];

  return links.map((link) => {
    return {
      ...link,
      ...(link.url ? {url: relativeToAbsoluteURLconverter(link.url)} : {}),
    };
  });
};

const defaultFooterNav = (options: I18NOptions): NavigationListItem[] => {
  const navItems = [
    shopify(options),
    resources(options),
    developers(options),
    products(options),
  ];

  navItems.push(globalImpact(options));

  navItems.push(solutions(options));

  return navItems.map((navItemCategory) => {
    //since utils are designed for shopify.com, we need to add the domain
    //to make it work as expected
    navItemCategory.navItems = navItemCategory.navItems.map((navItem) => {
      navItem.url = relativeToAbsoluteURLconverter(navItem.url);
      return navItem;
    });
    return navItemCategory;
  });
};

export const EngineeringBlogFooter = ({
  sectionName = 'footer',
  className,
  navItemClassName,
  navItemsBoxClassName,
  navFn = defaultFooterNav,
  legalNavFn = defaultFooterLegalNav,
  socialLinksFn = getSocialLinks,
}: FooterProps) => {
  const translations = useTranslations();
  const {t} = translations;
  const subNavs = navFn(translations);
  const legalNav = legalNavFn(translations);
  const showFooterManageConsent = useFooterManageConsent();
  const {updateShowCookieNotice} = useCookieNoticeBanner();
  const legalNavFiltered = legalNav.filter((value) => {
    return (
      !value.url?.includes(PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY) ||
      showFooterManageConsent
    );
  });
  const currentScheme = ColorSchemeEnum.Dark;
  const footerTextColor = 'text-white';
  const footerBGColor = 'bg-black';

  return (
    <footer
      className={cn(
        'relative flex flex-col font-shopifysans',
        footerBGColor,
        footerTextColor,
        className,
      )}
    >
      <div id="NewWindowExternalSite" className="hidden">
        {t('global:ariaLabels.footer.newWindow')}
      </div>
      <Section
        className={cn(
          'container flex flex-col gap-16 py-16 my-0',
          'sm:justify-start sm:pb-16',
          'md:flex-row md:flex-nowrap md:justify-between md:pb-20 md:py-20 md:gap-20',
          'xl:w-full',
          footerBGColor,
          footerTextColor,
        )}
        sectionName={sectionName}
        sectionIndex={1}
      >
        <div className="min-w-[100px]">
          <ShopifyLogo
            logoTheme={ShopifyLogoTheme.White}
            logoWithText={false}
            textColor={ShopifyLogoColor.White}
            className="h-[44px]"
          />
        </div>
        {subNavs.length > 0 && (
          <div className="md:block md:justify-end">
            <div
              className={cn(
                'flex flex-col flex-wrap gap-12 gap-x-4 md:gap-20 md:gap-x-16 sm:grid sm:max-h-fit sm:grid-cols-3 lg:grid-cols-4',
                {
                  'max-h-[120rem]': subNavs.length > 6,
                  'max-h-[92rem] sm:max-h-[75rem]': subNavs.length < 7,
                },
              )}
            >
              {subNavs.map(
                (subNav) =>
                  subNav &&
                  subNav.navItems &&
                  subNav.navItems.length > 0 && (
                    <div
                      key={subNav.content}
                      className="w-[calc(50%_-_1rem)] sm:w-fit"
                      data-component-name={kebab(subNav?.content as string)}
                    >
                      <h3 className={`text-base font-bold ${footerTextColor}`}>
                        {subNav.content}
                      </h3>
                      <ul className={cn('mt-4 md:mt-6', navItemsBoxClassName)}>
                        {subNav.navItems?.map((navItem) => (
                          <li key={navItem.content}>
                            <Anchor
                              className={cn(
                                'mt-2 block py-3 text-base font-normal hover:underline md:py-0.5',
                                linkColor(currentScheme),
                                navItemClassName,
                              )}
                              href={navItem.url ?? ''}
                              data-component-name={
                                navItem.content && kebab(navItem.content)
                              }
                            >
                              {navItem.content}
                            </Anchor>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ),
              )}
            </div>
          </div>
        )}
      </Section>
      <Section
        className={cn(
          'border-t my-0 container max-sm:mx-0 xl:w-full',
          'border-white border-opacity-20 bg-inherit',
        )}
        sectionName={sectionName}
        sectionIndex={2}
      >
        <div
          className={cn(
            'flex flex-col items-center justify-center gap-4 py-8',
            'sm:items-start sm:gap-8 lg:flex-row lg:justify-between lg:gap-10',
            footerBGColor,
            footerTextColor,
          )}
        >
          <div className="mt-1 flex w-full flex-col items-center justify-center sm:flex-row sm:items-start sm:justify-start sm:gap-8 lg:gap-14">
            <NavList
              className="flex flex-col items-center flex-wrap sm:flex-row sm:items-start gap-x-8 md:gap-x-10"
              componentName="bottom-nav"
              navItems={
                legalNavFiltered.map(({text, url, name}) => ({
                  content: text,
                  className:
                    url === PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY
                      ? 'cursor-pointer'
                      : '',
                  url:
                    url === PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY ? null : url,
                  name:
                    url === PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY
                      ? PRIVACY_PREFERENCES_MODAL_URL_COMPONENT_NAME
                      : name || kebab(text || ''),
                  onClick:
                    url === PRIVACY_PREFERENCES_MODAL_URL_HASH_KEY
                      ? () => updateShowCookieNotice(true)
                      : undefined,
                })) as NavItem[]
              }
              itemClass={cn(
                'mt-2 block py-3 sm:mt-0',
                linkColor(currentScheme),
              )}
              linkClass="hover:underline"
            />
          </div>
          <SocialIcons
            socialLinksFn={socialLinksFn}
            listClass="flex gap-4 md:gap-6"
            linkClass={cn('block h-8 w-8', 'fill-white hover:fill-shade-30')}
          />
        </div>
      </Section>
    </footer>
  );
};

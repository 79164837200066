import type {I18NOptions} from '@/types';
import type {SocialLink} from '@/components/shared/SocialIcons/SocialIcons';

import {ENGINEERING_SOCIAL_LINKS} from './constants';

export const getSocialLinks = (options: I18NOptions): SocialLink[] => {
  const {t, site} = options;
  const socials = [
    'facebook',
    'twitter',
    'youtube',
    'instagram',
    'tiktok',
    'linkedin',
    'pinterest',
  ];

  return socials.map((name: string): SocialLink => {
    return {
      name,
      text: t(`global:social.${name}.text`),
      url:
        ENGINEERING_SOCIAL_LINKS[
          name as keyof typeof ENGINEERING_SOCIAL_LINKS
        ] ||
        t(`global:social.${name}.url`, {
          context: site.countryCode.toLowerCase(),
        }),
    };
  });
};
